import { styled } from '@mui/material/styles';

import { Paragraph } from '../../../Paragraph';

export const StyledFinalCostsWrapper = styled('div')<{
    $gutterBottom?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: ${({ theme }) => theme.spacing(5)};
    padding-bottom: ${({ theme, $gutterBottom }) =>
        $gutterBottom ? theme.spacing(15) : theme.spacing(10)};
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
`;

export const StyledFinalCostsText = styled(Paragraph)`
    font-family: thegymgroupsans-Headline;
    text-transform: lowercase;
`;
