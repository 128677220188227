import { isNaN } from 'lodash';
import { Fragment } from 'react';

import { Spacing, SpacingSize } from '../../../Spacing';
import {
    StyledAmounts,
    StyledPaymentItemList,
    StyledPaymentItem,
    StyledOriginalPrice,
    StyledLabel,
    StyledDiscountedMessage,
} from './PaymentItemList.styled';
import { PaymentItemListProperties } from './PaymentItemList.types';
import { decimalToPoundsAndPence } from '@tgg/util';

export const PaymentItemList = ({
    items,
    title,
    gutterBottom = true,
    hasDiscountPriceInRed,
    hasSavingsItem,
}: PaymentItemListProperties) => {
    const savingsTotal = items.reduce(
        (accumulator, { cost, originalPrice }) =>
            accumulator +
            (originalPrice && !isNaN(Number(originalPrice))
                ? originalPrice - cost
                : 0),
        0,
    );
    return (
        <>
            <StyledPaymentItemList
                $gutterBottom={gutterBottom && !hasSavingsItem}
            >
                {items.map(
                    ({
                        label,
                        cost,
                        originalPrice,
                        itemHasLineThrough = false,
                        discountMessage,
                    }) => {
                        return (
                            <Fragment key={`${label}-${cost}`}>
                                <StyledPaymentItem
                                    data-testid={`paymentItem-${label}-${cost}`}
                                >
                                    <StyledLabel
                                        $applyStrikeThrough={itemHasLineThrough}
                                        gutterBottom={false}
                                    >
                                        {label}
                                    </StyledLabel>
                                    <StyledAmounts>
                                        {originalPrice && (
                                            <>
                                                <StyledOriginalPrice
                                                    gutterBottom={false}
                                                    aria-hidden
                                                    data-testid={`${title} ${label} Full Price`.toLowerCase()}
                                                >
                                                    {decimalToPoundsAndPence(
                                                        originalPrice,
                                                    )}
                                                </StyledOriginalPrice>
                                                <Spacing
                                                    size={SpacingSize.Small}
                                                />
                                            </>
                                        )}
                                        <StyledLabel
                                            gutterBottom={false}
                                            data-testid={`${title} ${label}`.toLowerCase()}
                                            $applyStrikeThrough={
                                                itemHasLineThrough
                                            }
                                            $hasDiscountPriceInRed={
                                                hasDiscountPriceInRed &&
                                                !!originalPrice &&
                                                originalPrice > cost
                                            }
                                        >
                                            {decimalToPoundsAndPence(cost)}
                                        </StyledLabel>
                                    </StyledAmounts>
                                </StyledPaymentItem>
                                {discountMessage && (
                                    <StyledDiscountedMessage
                                        variant="subtitle1"
                                        gutterBottom={false}
                                    >
                                        ({discountMessage})
                                    </StyledDiscountedMessage>
                                )}
                            </Fragment>
                        );
                    },
                )}
            </StyledPaymentItemList>
            {hasSavingsItem && savingsTotal > 0 ? (
                <StyledPaymentItemList
                    key={`savings-${savingsTotal}`}
                    $hasSavingsItem={hasSavingsItem}
                    $gutterBottom
                >
                    <StyledPaymentItem
                        data-testid={`paymentItem-savings-${savingsTotal}`}
                    >
                        <StyledLabel
                            $applyStrikeThrough={false}
                            gutterBottom={false}
                            $hasSavingsInRed={hasSavingsItem}
                        >
                            savings
                        </StyledLabel>
                        <StyledAmounts>
                            <StyledLabel
                                $applyStrikeThrough={false}
                                gutterBottom={false}
                                data-testid={`${title} savings`.toLowerCase()}
                                $hasSavingsInRed={hasSavingsItem}
                            >
                                {decimalToPoundsAndPence(savingsTotal)}
                            </StyledLabel>
                        </StyledAmounts>
                    </StyledPaymentItem>
                </StyledPaymentItemList>
            ) : null}
        </>
    );
};
