export enum SpacingSize {
    ExtraSmall = 'extraSmall',
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
    ExtraLarge = 'extraLarge',
}

export interface SpacingProperties {
    /**
     * Size variant of the spacing - scales differently on mobile and desktop.
     */
    size: SpacingSize;
}
