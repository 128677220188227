import { addMonths } from 'date-fns';

import { Paragraph } from '../../../Paragraph';
import { StyledBoldText } from './MonthlyPaymentTileDescription.styled';
import { MonthlyPaymentTileDescriptionProperties } from './MonthlyPaymentTileDescription.types';
import { getFormattedDate } from '@tgg/util';

export function MonthlyPaymentTileDescription({
    isSteppedKickerPromo,
    membershipStartDate,
    discountDuration,
    isOpeningSoon,
}: MonthlyPaymentTileDescriptionProperties) {
    if (isOpeningSoon) {
        return (
            <Paragraph variant="subtitle1" gutterBottom={false} role="note">
                Subsequent monthly payments will start one month from the gym
                opening date. Payments will then continue on or just after the
                same day of each month thereafter.
            </Paragraph>
        );
    }

    if (isSteppedKickerPromo && membershipStartDate && discountDuration) {
        const nextBillingDate = getFormattedDate(
            String(addMonths(new Date(membershipStartDate), 1)),
            'd MMMM yyyy',
        );
        return (
            <Paragraph variant="subtitle1" gutterBottom={false} role="note">
                Subsequent monthly payments will commence on{' '}
                <StyledBoldText>{nextBillingDate}.</StyledBoldText> Payments
                will then continue on or just after the same day of each month
                thereafter.{' '}
                <StyledBoldText>
                    For the next {discountDuration - 1} months, the promotional
                    discount will be automatically applied.
                </StyledBoldText>
            </Paragraph>
        );
    }

    return (
        <>
            Subsequent monthly payments will commence one month from today.
            Payments will then continue on or just after the same day of each
            month thereafter.
        </>
    );
}
