import { addMonths, format } from 'date-fns';

import {
    Nullable,
    PaymentItemsWithTotal,
    SteppedKickerPaymentItems,
} from '@tgg/common-types';

export const getDiscountDuration = (
    steppedKicker?: Nullable<SteppedKickerPaymentItems>,
) => steppedKicker?.promotionPrices?.length || null;

export const mapSteppedKickerForPaymentSummary = (
    steppedKickerFromCookie: SteppedKickerPaymentItems,
): Nullable<PaymentItemsWithTotal[]> => {
    const {
        productDescription,
        membershipStartDate,
        productOriginalPrice,
        promotionPrices,
        addOns,
    } = steppedKickerFromCookie;

    return promotionPrices.map((promotionPrice, index) => {
        const isNotLastItem = index < promotionPrices.length - 1;
        const steppedKickerPaymentItem = {
            label: productDescription || '',
            cost: promotionPrice,
            discountDuration: index + 1,
            originalPrice: isNotLastItem ? productOriginalPrice : null,
        };
        return {
            items: [steppedKickerPaymentItem, ...addOns],
            total:
                steppedKickerPaymentItem.cost +
                addOns.reduce(
                    (accumulator, item) => accumulator + item.cost,
                    0,
                ),
            totalText: getMonthlyTotalText({
                membershipStartDate,
                promotionInstance: index + 1,
                isLastItem: !isNotLastItem,
            }),
        };
    });
};

export const getMonthlyTotalText = ({
    membershipStartDate,
    promotionInstance,
    isLastItem,
}: {
    membershipStartDate: string;
    promotionInstance: number;
    isLastItem: boolean;
}) => {
    const monthName = format(
        addMonths(new Date(membershipStartDate), promotionInstance),
        'MMMM',
    );
    return `total: ${monthName}${isLastItem ? ' onwards' : ''}`;
};
