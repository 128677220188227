import { StyledSpacing } from './Spacing.styled';
import { SpacingProperties } from './Spacing.types';

/**
 *  Use the Spacing component to add a block of empty space between any other components.
 */
export function Spacing({ size }: SpacingProperties) {
    return <StyledSpacing size={size} data-testid={`spacing-${size}`} />;
}

export default Spacing;
