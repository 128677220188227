export function decimalToPoundsAndPence(
    amount: number,
    includeZeroDecimal = true,
): string {
    const parsedAmount = Number(amount) || 0;
    const [pounds, pence] = parsedAmount.toFixed(2).split('.');

    if (pence === '00' && !includeZeroDecimal) {
        return `£${pounds}`;
    }

    return `£${pounds}.${pence}`;
}

export function decimalPoundsToPence(amount: number): number {
    return Number((amount * 100).toFixed(0));
}

export function getTotalCost(amount?: number) {
    return typeof amount === 'number' ? amount : 0;
}
