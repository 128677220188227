import { Fragment } from 'react';

import { palette } from '../../../theme';
import { Heading } from '../../Heading';
import { HorizontalRule } from '../../HorizontalRule';
import { Paragraph } from '../../Paragraph';
import { Spacing, SpacingSize } from '../../Spacing';
import { Tile } from '../../Tile';
import { PaymentItemList } from './PaymentItemList';
import {
    StyledBody,
    StyledDescription,
    StyledHeading,
} from './PaymentTile.styled';
import { PaymentTileProperties } from './PaymentTile.types';
import { TotalCost } from './TotalCost/TotalCost';
import { OptimizelyFeatureFlags } from '@tgg/common-types';
import { useFlagsContext, useAppConfigContext } from 'libs/ui/src/lib/contexts';

export function PaymentTile({
    title,
    description,
    items,
    steppedKickerPaymentItems,
    total,
    gutterBottom = false,
}: PaymentTileProperties) {
    const {
        flags: {
            [OptimizelyFeatureFlags.SAVINGS_VISIBILITY_VARIATIONS]: {
                enabled: isSavingsVariationsEnabled,
                variationKey: savingsVariationKey,
            },
        },
    } = useFlagsContext();
    const {
        appConfig: { appIdentifier },
    } = useAppConfigContext();
    const hasSavingsItem =
        (savingsVariationKey === 'v2' || savingsVariationKey === 'v3') &&
        appIdentifier === 'commerce' &&
        isSavingsVariationsEnabled;
    const hasDiscountPriceInRed =
        (savingsVariationKey === 'v1' || savingsVariationKey === 'v3') &&
        appIdentifier === 'commerce' &&
        isSavingsVariationsEnabled;

    const showPaymentItems = items && items.length > 0;
    const showSteppedKickerPaymentItems =
        steppedKickerPaymentItems && steppedKickerPaymentItems.length > 0;

    return (
        <Tile gutterBottom={gutterBottom}>
            <StyledHeading>
                <Heading
                    color={palette.common.blue}
                    variant="h3"
                    gutterBottom={false}
                >
                    {title.toLowerCase()}
                </Heading>
            </StyledHeading>
            <StyledBody>
                {description && (
                    <>
                        <StyledDescription>
                            {typeof description === 'string' ? (
                                <Paragraph
                                    variant="subtitle1"
                                    gutterBottom={false}
                                    role="note"
                                >
                                    <span
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}
                                    />
                                </Paragraph>
                            ) : (
                                description
                            )}
                        </StyledDescription>
                        <HorizontalRule
                            color={palette.grey[50]}
                            variant="fullWidth"
                        />
                    </>
                )}
                {showPaymentItems && (
                    <>
                        <PaymentItemList
                            items={items}
                            title={title}
                            hasSavingsItem={hasSavingsItem}
                            hasDiscountPriceInRed={hasDiscountPriceInRed}
                        />

                        {hasSavingsItem && (
                            <Spacing size={SpacingSize.ExtraSmall} />
                        )}
                        <HorizontalRule
                            color={palette.grey[50]}
                            variant="fullWidth"
                        />
                    </>
                )}
                {showSteppedKickerPaymentItems &&
                    steppedKickerPaymentItems.map(
                        (steppedKickerItem, index) => {
                            const isLastElement =
                                index === steppedKickerPaymentItems.length - 1;
                            return (
                                <Fragment
                                    key={`${steppedKickerItem.totalText} - ${steppedKickerItem.total}`}
                                >
                                    <PaymentItemList
                                        items={steppedKickerItem.items}
                                        title={title}
                                        gutterBottom={false}
                                        hasSavingsItem={hasSavingsItem}
                                        hasDiscountPriceInRed={
                                            hasDiscountPriceInRed
                                        }
                                    />
                                    <TotalCost
                                        title={title}
                                        totalCost={
                                            steppedKickerItem.total as number
                                        }
                                        totalText={steppedKickerItem.totalText}
                                        gutterBottom={isLastElement}
                                    />
                                    {!isLastElement && (
                                        <HorizontalRule
                                            color={palette.grey[50]}
                                            variant="fullWidth"
                                        />
                                    )}
                                </Fragment>
                            );
                        },
                    )}
                {!!total && <TotalCost title={title} totalCost={total} />}
            </StyledBody>
        </Tile>
    );
}

export default PaymentTile;
